// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const superAdminAccess = [
  "View Investor",
  "Add Investor",
  "Edit Investor",
  "Terminate Investor",
  "View Core Team",
  "Edit Core Team",
  "Remove Core Team",
  "View Manager",
  "View User",
  "Add User",
  "Edit User",
  "Revoke User",
  "Update Email",
  "Update Password",
  "Update Vesting Period",
  "Update Locking Period",
  "Update Payout Status"
]

const adminAccess = [
  "View Investor",
  "Add Investor",
  "Edit Investor",
  "Terminate Investor",
  "View Core Team",
  "Edit Core Team",
  "Remove Core Team",
  "View Manager",
  "View User",
  "Update Email",
  "Update Password",
]

export const environment = {
  production: false,
  uat: false,
  baseApiUrl: 'https://tokenvault-uat-be.myegdev.com',
  sentryDSN: 'https://625d9efc0d4b4f058fcb09f70c4ee35d@sentry.myeg.com.my/58',
  sentryEnv: 'dev',
  recaptchakey: '6Ldbc40jAAAAAAvYXben0anU4ifXPNwS7wzILZ6u',
  // recaptchakey: '6Lc_pY0jAAAAALLThWlVier1jM4zjm--Qx6431wr',
  superAdminAccess: superAdminAccess,
  adminAccess: adminAccess,
  zetrixUrl: 'https://test-explorer.zetrix.com/tx/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
